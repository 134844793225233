import React from 'react';

export const YellowCardSvg: React.FC = () => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={40}
      height={40}
      fill='none'
    >
      <rect
        width={40}
        height={40}
        fill='#FFF7F2'
        rx={20}
      />
      <path
        fill='#040325'
        stroke='#040325'
        strokeWidth={0.5}
        d='M14.791 9.502a2.348 2.348 0 0 0-2.343 2.344v16.32a2.348 2.348 0 0 0 2.343 2.343h10.422c1.29 0 2.35-1.053 2.35-2.343v-16.32c0-1.29-1.06-2.344-2.35-2.344H14.79Zm0 .75h10.422c.884 0 1.599.709 1.599 1.594v16.32a1.59 1.59 0 0 1-1.6 1.591H14.792a1.585 1.585 0 0 1-1.593-1.591v-16.32c0-.885.708-1.593 1.593-1.593Zm4.197 4.127a.85.85 0 0 0-.84.93l.715 6.708a.966.966 0 0 0 .954.859h.56a.943.943 0 0 0 .936-.865l.55-6.707a.863.863 0 0 0-.853-.925h-2.022Zm0 .698h2.022c.097 0 .163.076.155.173l-.543 6.702a.241.241 0 0 1-.245.226h-.56a.258.258 0 0 1-.262-.232l-.715-6.708c-.01-.09.056-.161.148-.161Zm.31 8.604a.498.498 0 0 0-.47.494v.954c0 .27.224.502.494.502h1.366c.27 0 .495-.231.495-.502v-.954a.498.498 0 0 0-.495-.494h-1.39Zm.228.698h.96v.554h-.96v-.554Z'
      />
    </svg>
  );
};
