import React from 'react';

export const WJSvg: React.FC = () => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={40}
      height={40}
      fill='none'
    >
      <rect
        width={40}
        height={40}
        fill='#FFF7F2'
        rx={20}
      />
      <g fill='#040325'>
        <path d='m12.858 24.988-2.05-9.702a.529.529 0 1 0-1.034.218l2.05 9.703a.529.529 0 1 0 1.034-.219ZM11.095 24.988l-2.049-9.702a.529.529 0 1 0-1.034.218l2.049 9.703a.53.53 0 0 0 1.034-.219ZM30.942 14.92h-3.321c-.307 0-.582.13-.775.337a1.054 1.054 0 0 0-.776-.338h-.627l-.017.001h.002-2.965c-.313 0-.598.136-.793.356a1.063 1.063 0 0 0-.322-.226 1.054 1.054 0 0 0-.51-.13h-3.432c-.286 0-.548.114-.74.302a1.056 1.056 0 0 0-.741-.303h-3.096a1.058 1.058 0 0 0-1.034 1.277l1.809 8.562c.103.49.534.84 1.034.84h3.765c.294 0 .563-.121.757-.32.193.199.462.32.756.32H23.644c.237 0 .458-.08.637-.215.099.036.2.069.301.096.603.207 1.335.303 2.3.303h.148c.03 0 .058-.001.086-.004 1.5-.034 2.68-.4 3.507-1.09a.77.77 0 0 0 .025-.022c.897-.797 1.352-1.97 1.352-3.485v-5.204c0-.584-.474-1.058-1.058-1.058Zm-7.774 9.311c-.046.217-.172.288-.276.309h-2.976l-.756-5.404-.757 5.404h-3.765l-1.808-8.563h3.095l.723 4.928.758-4.928h3.433l.005.037h.069l.757 5.263.792-5.3h2.44l-1.734 8.254Zm7.774-3.05c0 1.218-.332 2.104-.996 2.695-.64.533-1.624.825-2.916.846v.002h-.148c-.812 0-1.476-.074-1.993-.258a2.53 2.53 0 0 1-.636-.256l1.707-8.124a.525.525 0 0 0 .012-.109h.098v5.315c0 .369.037.664.185.849a.718.718 0 0 0 .59.295c.222 0 .407-.11.554-.295.148-.185.221-.48.221-.812v-5.352h3.322v5.204Z' />
      </g>
      <defs>
        <clipPath id='a'>
          <path
            fill='#fff'
            d='M8 8h24v24H8z'
          />
        </clipPath>
      </defs>
    </svg>
  );
};
