import React from 'react';

type Props = {
  color?: string;
};

export const LotterySvg: React.FC<Props> = ({color = '#FF8A71'}) => {
  return (
    <svg
        width={50}
        height={24}
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        viewBox="0 0 512 512"
        xmlSpace="preserve"
    >
        <g>
            <path
                fill={color}
                d="M216.759,239.367l36.235-36.243c-42.142-19.36-93.728-11.743-128.391,22.92
                    c-44.488,44.496-44.488,116.872,0,161.352c44.48,44.497,116.864,44.497,161.353,0c34.663-34.663,42.275-86.249,22.912-128.395
                    l-36.235,36.243c3.592,21.121-2.672,43.6-18.951,59.879c-26.693,26.685-70.12,26.685-96.809,0
                    c-26.697-26.693-26.697-70.113,0-96.806C173.146,242.039,195.631,235.775,216.759,239.367z"
            />
            <path
                fill={color}
                d="M356.787,256.392c18.353,55.516,5.53,119.183-38.562,163.278c-62.279,62.276-163.616,62.276-225.896,0
                    c-62.276-62.276-62.276-163.616,0-225.884c44.103-44.111,107.762-56.932,163.286-38.57l-5.274-48.749
                    c-66.375-14.824-138.732,3.49-190.282,55.037c-80.08,80.079-80.08,210.368,0,290.44c80.071,80.07,210.356,80.07,290.431,0
                    c51.551-51.555,69.861-123.9,55.032-190.27L356.787,256.392z"
            />
            <path
                fill={color}
                d="M288.667,206.181l-78.346,78.35c-7.385-1.682-15.43,0.314-21.183,6.06c-8.91,8.906-8.91,23.352,0,32.266
                    c8.913,8.913,23.356,8.913,32.273,0c5.754-5.753,7.735-13.802,6.061-21.182l78.349-78.342l96.727,10.469l3.856-3.86L512,124.342
                    l-112.203-12.136L387.649,0.003L278.197,109.455l0.589,5.432L288.667,206.181z M394.138,209.939l-67.609-7.318l23.533-23.541
                    l-0.153-0.142l67.891,7.341L394.138,209.939z M393.647,134.481l68.528,7.42l-23.663,23.674l-68.529-7.42L393.647,134.481z
                    M370.094,49.828l7.412,68.525l-23.659,23.658l-7.408-68.516L370.094,49.828z M325.72,94.198l7.349,67.888l-0.154-0.157
                    l-23.537,23.541l-7.321-67.621L325.72,94.198z"
            />
        </g>
    </svg>

  );
};
