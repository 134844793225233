import React from 'react';

export const PercentageSvg: React.FC = () => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={24}
      height={24}
      fill='none'
    >
      <path
        fill='#FF8A71'
        d='M17.202 13.116c-3.245-1.291-6.99 1.285-6.912 4.779-.008 4.523 5.528 6.811 8.723 3.615 2.627-2.562 1.598-7.15-1.811-8.394Zm-2.11 4.386h.612c1.684.035 1.76 2.486.087 2.629v.29a.394.394 0 0 1-.393.393.394.394 0 0 1-.393-.393v-.287h-.837c-.516-.01-.517-.778 0-.785h1.537c.697-.013.697-1.052 0-1.061h-.614c-1.683-.036-1.76-2.487-.086-2.63v-.29c0-.216.177-.393.393-.393.216 0 .393.177.393.393v.287h.837c.516.01.517.778 0 .786h-1.537c-.697.012-.697 1.05 0 1.06Z'
      />
      <path
        fill='#FF8A71'
        d='M8.333 13.199c.621-.03 1.284-.026 1.894.02.471.035.927.101 1.367.184a5.84 5.84 0 0 1 3.812-1.399c.804-.006 1.63.17 2.362.491a7.012 7.012 0 0 0 1.56-4.425c.014-6.163-7.484-9.39-11.943-5.132-2.97 2.712-2.89 7.714.08 10.335.353-.043.655-.063.868-.074Zm7.065-2.841c-.056 1.893-2.464 1.888-2.519 0 .056-1.895 2.462-1.899 2.519 0ZM13.834 4.55c.266-.441.932-.063.68.393l-3.84 6.648c-.265.445-.931.057-.68-.393l3.84-6.648Zm-3.462-.197c1.662.06 1.658 2.806 0 2.865-1.664-.058-1.667-2.807 0-2.865Z'
      />
      <path
        fill='#FF8A71'
        d='M10.372 6.432c.617-.024.62-1.268 0-1.293-.624.017-.62 1.277 0 1.293ZM13.665 10.357c.02.845.929.848.947 0-.025-.855-.92-.851-.947 0ZM10.144 20.567c-.682.162-1.444.056-2.142.039-1.227-.091-2.568-.33-3.619-.94a3.074 3.074 0 0 1-.38-.267v.46c0 1.29 2.57 1.965 5.108 1.965.624 0 1.23-.04 1.795-.114a5.399 5.399 0 0 1-.762-1.143ZM4.788 18.995c1.014.575 2.332.774 3.513.84.496.03 1.036.032 1.533.009-.008-.028-.02-.055-.028-.083a5.43 5.43 0 0 1-.251-1.088 8.465 8.465 0 0 1-.444.008c-2.194-.043-3.885-.334-5.109-1.25 0 .65-.078 1.011.786 1.564ZM4.788 17.026c1.004.619 3.024.925 4.716.86a5.815 5.815 0 0 1 1.399-3.803 12.891 12.891 0 0 0-1.792-.118c-2.035-.05-5.1.616-5.101 1.886-.05.479.264.862.778 1.175Z'
      />
    </svg>
  );
};
