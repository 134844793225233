import React from 'react';

type Props = {
  style?: React.CSSProperties;
};

export const PadLockSvg: React.FC<Props> = ({style}) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={100}
      height={100}
      fill='none'
      style={{...style}}
    >
      <path
        fill='#040325'
        d='M49.984 90.446a40.325 40.325 0 0 1-16.579-3.585 1.351 1.351 0 1 1 1.111-2.464 37.72 37.72 0 0 0 49.881-18.919 37.891 37.891 0 0 0-3.677-37.385 1.351 1.351 0 1 1 2.203-1.566 40.608 40.608 0 0 1 3.938 40.068 40.527 40.527 0 0 1-36.877 23.851Z'
      />
      <path
        fill='#6C6D84'
        d='M24.297 21.919a1.351 1.351 0 0 1-.894-2.365 39.447 39.447 0 0 1 3.33-2.616 1.352 1.352 0 0 1 1.562 2.207 36.147 36.147 0 0 0-3.109 2.432c-.245.218-.56.34-.889.342Z'
      />
      <circle
        cx={50.333}
        cy={50.333}
        r={33.667}
        fill='#FFD9C3'
      />
      <path
        fill='#6C6D84'
        d='M16.081 70.743a1.352 1.352 0 0 1-1.174-.675 40.169 40.169 0 0 1-4.068-30.136 1.352 1.352 0 1 1 2.616.676 37.492 37.492 0 0 0 3.798 28.108 1.35 1.35 0 0 1-1.172 2.027ZM15.864 32.324a1.351 1.351 0 0 1-1.179-2.01 38.867 38.867 0 0 1 2.185-3.487 1.35 1.35 0 1 1 2.206 1.562 35.871 35.871 0 0 0-2.027 3.243 1.352 1.352 0 0 1-1.185.692Z'
      />
      <path
        fill='#040325'
        d='M66.04 15.716c-.19 0-.38-.04-.553-.119a37.558 37.558 0 0 0-28.514-1.005 1.351 1.351 0 0 1-.932-2.537 40.223 40.223 0 0 1 30.547 1.082 1.351 1.351 0 0 1-.554 2.583l.006-.004Z'
      />
      <path
        fill='#040325'
        d='M55.728 19.642a1.351 1.351 0 0 1-.478-2.616l9.054-3.434-3.435-9.054a1.352 1.352 0 0 1 2.528-.957l3.913 10.322a1.352 1.352 0 0 1-.784 1.742l-10.322 3.919a1.334 1.334 0 0 1-.476.078ZM37.864 97.297a1.351 1.351 0 0 1-1.265-.873l-3.912-10.321a1.35 1.35 0 0 1 .783-1.742l10.322-3.92a1.353 1.353 0 0 1 1.721 1.82 1.352 1.352 0 0 1-.763.708l-9.054 3.434 3.435 9.054a1.35 1.35 0 0 1-1.267 1.84Z'
      />
      <path
        fill='#55ACEE'
        d='M36.667 46H66v18H36.667z'
      />
      <path
        fill='#040325'
        d='M64.962 66.095H37.741a2.716 2.716 0 0 1-2.714-2.717V47.446a2.716 2.716 0 0 1 2.714-2.716h27.221a2.716 2.716 0 0 1 2.714 2.716v15.932a2.716 2.716 0 0 1-2.714 2.717ZM37.741 47.432l-.011.01v15.936l27.232.01.011-15.946-27.232-.01Z'
      />
      <path
        fill='#040325'
        d='M60.88 47.432H41.826a1.351 1.351 0 0 1-1.352-1.35v-8.164a10.88 10.88 0 0 1 21.757 0v8.163a1.351 1.351 0 0 1-1.351 1.351ZM43.177 44.73h16.351v-6.812a8.177 8.177 0 0 0-16.351 0v6.812ZM51.351 58.378A1.352 1.352 0 0 1 50 57.027v-3.23a1.351 1.351 0 0 1 2.703 0v3.23a1.352 1.352 0 0 1-1.352 1.351Z'
      />
    </svg>
  );
};
