import H1 from './H1';
import H2 from './H2';
import H3 from './H3';
import H4 from './H4';
import H5 from './H5';
import {H6} from './H6';

import T10 from './T10';
import T12 from './T12';
import T14 from './T14';
import T16 from './T16';

export const text = {
  H1,
  H2,
  H3,
  H4,
  H5,
  H6,
  T10,
  T12,
  T14,
  T16,
};
