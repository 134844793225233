import React from 'react';

type Props = {
  style?: React.CSSProperties;
};

export const CancelSvg: React.FC<Props> = ({style}) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={100}
      height={100}
      fill='none'
      style={{...style}}
    >
      <circle
        cx={50.827}
        cy={50.173}
        r={33.019}
        fill='#FFD9C3'
      />
      <path
        fill='#040325'
        d='M89.576 34.174c-2.183-5.03-5.288-9.578-9.23-13.52-3.942-3.941-8.49-7.047-13.52-9.23C61.59 9.152 56.099 8 50.5 8s-11.091 1.152-16.326 3.424c-5.03 2.183-9.578 5.289-13.52 9.23-3.941 3.942-7.047 8.49-9.23 13.52C9.152 39.41 8 44.901 8 50.5s1.152 11.091 3.424 16.326c2.183 5.03 5.289 9.578 9.23 13.52 3.942 3.941 8.49 7.047 13.52 9.23C39.41 91.848 44.901 93 50.5 93s11.091-1.152 16.326-3.424c5.03-2.183 9.578-5.289 13.52-9.23 3.941-3.942 7.047-8.49 9.23-13.52C91.848 61.59 93 56.099 93 50.5s-1.152-11.091-3.424-16.326ZM50.5 89.68c-21.238 0-39.18-17.943-39.18-39.18 0-21.238 17.942-39.18 39.18-39.18 21.237 0 39.18 17.942 39.18 39.18 0 21.237-17.943 39.18-39.18 39.18Z'
      />
      <path
        fill='#6C6D84'
        d='M83.035 36.854A35.939 35.939 0 0 0 75.39 25.61a35.939 35.939 0 0 0-11.244-7.645A34.33 34.33 0 0 0 50.5 15.14c-6.385 0-13.041 2.18-19.249 6.305a1.66 1.66 0 1 0 1.838 2.765c5.661-3.761 11.682-5.75 17.411-5.75 17.368 0 32.041 14.673 32.041 32.041 0 17.368-14.673 32.041-32.041 32.041-17.368 0-32.041-14.673-32.041-32.041 0-5.728 1.988-11.75 5.749-17.411a1.66 1.66 0 1 0-2.766-1.837C17.32 37.46 15.14 44.116 15.14 50.5c0 4.697.95 9.288 2.826 13.646A35.939 35.939 0 0 0 25.61 75.39a35.938 35.938 0 0 0 11.244 7.645A34.329 34.329 0 0 0 50.5 85.86c4.697 0 9.288-.95 13.646-2.826A35.939 35.939 0 0 0 75.39 75.39a35.938 35.938 0 0 0 7.645-11.244A34.329 34.329 0 0 0 85.86 50.5c0-4.697-.95-9.288-2.826-13.646Z'
      />
      <path
        fill='#FF8A71'
        d='M38.404 33.173c-1.09 1.09-3.335 3.4-3.596 3.923V42l9.154 8.173-9.808 10.462.327 4.904 5.557 1.634L50.5 58.346l9.154 8.173h5.884l1.962-4.904-10.462-10.788L66.52 42l-.98-6.538c-1.744-.436-5.428-1.308-6.212-1.308-.785 0-6.212 6.102-8.827 9.154L38.404 33.173Z'
      />
      <path
        fill='#040325'
        d='m59.89 50.5 6.926-6.925a6.594 6.594 0 0 0 1.946-4.696 6.594 6.594 0 0 0-1.946-4.695 6.597 6.597 0 0 0-4.695-1.946c-1.773 0-3.44.691-4.695 1.946L50.5 41.11l-6.926-6.926a6.597 6.597 0 0 0-4.695-1.946c-1.773 0-3.44.691-4.695 1.945a6.594 6.594 0 0 0-1.946 4.696c0 1.774.691 3.442 1.946 4.695L41.11 50.5l-6.926 6.925a6.594 6.594 0 0 0-1.946 4.696c0 1.774.691 3.442 1.946 4.695a6.597 6.597 0 0 0 4.695 1.946c1.773 0 3.44-.691 4.695-1.946L50.5 59.89l6.926 6.926a6.597 6.597 0 0 0 4.695 1.946c1.773 0 3.44-.691 4.695-1.945a6.594 6.594 0 0 0 1.946-4.696 6.594 6.594 0 0 0-1.946-4.695L59.89 50.5Zm4.578 13.968a3.299 3.299 0 0 1-2.347.973c-.886 0-1.72-.345-2.347-.973l-8.1-8.1a1.655 1.655 0 0 0-1.174-.486c-.425 0-.85.162-1.174.486l-8.1 8.1a3.299 3.299 0 0 1-2.347.973 3.3 3.3 0 0 1-2.348-.973 3.296 3.296 0 0 1-.972-2.347c0-.887.345-1.72.973-2.347l8.1-8.1a1.66 1.66 0 0 0 0-2.348l-8.1-8.1a3.296 3.296 0 0 1-.973-2.347c0-.887.345-1.72.973-2.347a3.299 3.299 0 0 1 2.347-.973c.886 0 1.72.345 2.347.973l8.1 8.1a1.66 1.66 0 0 0 2.348 0l8.1-8.1a3.298 3.298 0 0 1 2.347-.973 3.3 3.3 0 0 1 2.348.973c.627.627.972 1.46.972 2.347s-.345 1.72-.973 2.347l-8.1 8.1a1.66 1.66 0 0 0 0 2.348l8.1 8.1c.628.627.973 1.46.973 2.347s-.345 1.72-.973 2.347ZM26.926 25.432a1.66 1.66 0 1 0 .001 3.321 1.66 1.66 0 0 0-.001-3.321Z'
      />
    </svg>
  );
};
