import React from 'react';

export const PiggyBankSvg: React.FC = () => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={52}
      height={52}
      fill='none'
    >
      <path
        fill='#6C6D84'
        fillOpacity={0.1}
        stroke='#fff'
        strokeWidth={4}
        d='M10 26c0-8.837 7.163-16 16-16s16 7.163 16 16-7.163 16-16 16-16-7.163-16-16ZM26 2C12.745 2 2 12.745 2 26s10.745 24 24 24 24-10.745 24-24S39.255 2 26 2Z'
      />
      <path
        stroke='#FF8A71'
        strokeLinecap='round'
        strokeWidth={4}
        d='M26 46c11.046 0 20-8.954 20-20S37.046 6 26 6'
      />
      <g fill='#FF8A71'>
        <path d='M32.857 23.885h-.267a6.537 6.537 0 0 0-1.925-2.665v-.007c-.133-.459-.033-.95.287-1.463a.83.83 0 0 0 .023-.855.842.842 0 0 0-.839-.43c-1.225.152-1.958.71-2.38 1.23a8.534 8.534 0 0 0-2.266-.305c-1.991 0-3.864.68-5.277 1.916C18.786 22.554 18 24.218 18 25.992a5.795 5.795 0 0 0 .168 1.39s.004.007.007.02c.112.452.277.895.492 1.314.353.77.78 1.41 1.258 1.906.68.7.796 1.585.813 1.941v.209a.802.802 0 0 0 .8.766h2.46c.438 0 .798-.36.798-.8v-.165c.383.03.77.037 1.156.017v.152c0 .439.36.799.8.799h2.46c.439 0 .799-.36.799-.8v-.6c.003-.142.06-.816.697-1.397l.092-.08.007-.006c.006-.007.016-.013.023-.02a6.53 6.53 0 0 0 1.8-2.625h.23c.628 0 1.14-.512 1.14-1.14v-1.836a1.15 1.15 0 0 0-1.143-1.152Zm.347 2.975a.347.347 0 0 1-.347.347h-.515a.396.396 0 0 0-.38.28c-.293.958-.875 1.843-1.677 2.56l-.007.007-.003.003-.09.08c-.002.003-.006.006-.01.006-.864.786-.954 1.71-.96 1.975v.617c0 .003-.003.007-.007.007h-2.46c-.003 0-.007-.004-.007-.007v-.578a.396.396 0 0 0-.396-.396c-.013 0-.026 0-.043.003a7.73 7.73 0 0 1-1.853-.026.399.399 0 0 0-.45.393v.604c.001.003-.002.007-.005.007h-2.46c-.004 0-.007-.004-.007-.007v-.201c-.02-.443-.159-1.566-1.037-2.467-.42-.433-.796-1-1.116-1.691-.003-.007-.003-.01-.007-.013a5.281 5.281 0 0 1-.432-1.153c0-.003-.004-.006-.004-.01v-.006a5.055 5.055 0 0 1-.142-1.196c0-3.203 3.005-5.812 6.697-5.812.79 0 1.56.119 2.292.35.169.053.35-.01.45-.155.293-.436.878-.98 2-1.123a.048.048 0 0 1 .05.027c.013.023.004.043-.003.05-.44.7-.568 1.426-.377 2.1l.004.013a.39.39 0 0 0 .148.297c.899.726 1.55 1.648 1.883 2.665a.398.398 0 0 0 .376.274h.545c.192 0 .347.155.347.347v1.83h.003Z' />
        <path d='M25.92 25.596h-.886a.575.575 0 0 1 0-1.15h1.565a.397.397 0 0 0 .397-.396.397.397 0 0 0-.397-.396h-.723v-.532a.397.397 0 0 0-.396-.396.397.397 0 0 0-.396.396v.532h-.047a1.37 1.37 0 0 0-1.367 1.367 1.37 1.37 0 0 0 1.367 1.367h.885a.575.575 0 0 1 0 1.15h-1.591a.397.397 0 0 0-.397.396c0 .217.179.396.397.396h.753v.541c0 .218.178.397.396.397a.397.397 0 0 0 .396-.397v-.541h.066a1.37 1.37 0 0 0 1.35-1.367 1.38 1.38 0 0 0-1.373-1.367Z' />
      </g>
      <defs>
        <clipPath id='a'>
          <path
            fill='#fff'
            d='M18 18h16v16H18z'
          />
        </clipPath>
      </defs>
    </svg>
  );
};
