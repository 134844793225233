import React from 'react';

export const HeartSvg: React.FC = () => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={40}
      height={40}
      fill='none'
    >
      <rect
        width={40}
        height={40}
        fill='#fff'
        rx={20}
      />
      <path
        stroke='#040325'
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeWidth={1.5}
        d='M25.893 13.688a3.702 3.702 0 0 0-1.19-.954 3.144 3.144 0 0 0-1.403-.335c-.482 0-.959.113-1.404.335-.445.22-.849.545-1.19.954l-.706.848-.707-.848c-.688-.825-1.62-1.289-2.593-1.289-.973 0-1.905.464-2.593 1.289s-1.074 1.945-1.074 3.112.386 2.287 1.074 3.112l.706.848L20 26.984l5.187-6.224.706-.848c.341-.409.611-.894.796-1.428.184-.534.279-1.106.279-1.684 0-.578-.095-1.15-.28-1.684a4.543 4.543 0 0 0-.795-1.428v0Z'
      />
    </svg>
  );
};
