import React from 'react';

type Props = {
  style?: React.CSSProperties;
};

export const BillSvg: React.FC<Props> = ({style}) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={100}
      height={100}
      fill='none'
      style={{...style}}
    >
      <path
        fill='#FFD9C3'
        d='m26.666 19.333 37-11 4.667 1 19 61-2.333 4-50.667 15.334L31 87.333 15.666 37l-3-10 .667-3.333 4.333-1V36l2 2.667 4.667 1L26.667 36V19.333Z'
      />
      <path
        fill='#55ACEE'
        d='M31 87.667 13 31v58.667l4 2.666h50.667l4-2.666V78.333L35 89.667l-4-2Z'
      />
      <path
        fill='#040325'
        d='M71.501 87a1.278 1.278 0 0 0-1.426 1.113 3.04 3.04 0 0 1-3.017 2.662H16.933a3.044 3.044 0 0 1-3.041-3.041V35.949l6.957 22.936a1.28 1.28 0 0 0 1.594.853c.677-.205 1.06-.92.853-1.595L16.914 37.1a5.897 5.897 0 0 0 5.466 3.698 5.899 5.899 0 0 0 5.89-5.89V20.213c8.524-2.587 18.76-5.69 28.244-8.567a1.281 1.281 0 0 0 .853-1.596 1.282 1.282 0 0 0-1.596-.853l-23.665 7.178a8.263 8.263 0 0 0-7.359-4.514c-4.553 0-8.258 3.704-8.258 8.258v.994l-1.143.346a5.563 5.563 0 0 0-3.973 5.356l-.04 1.515v59.403c0 3.089 2.512 5.6 5.6 5.6h50.125c2.822 0 5.21-2.11 5.556-4.907A1.278 1.278 0 0 0 71.502 87ZM19.048 20.12c0-3.143 2.556-5.7 5.7-5.7a5.697 5.697 0 0 1 4.854 2.717l-2.207.669c-2.029-2.334-5.882-1.174-6.265 1.9-.045.352-.03 12.666-.029 13.137a1.28 1.28 0 0 0 2.559 0v-12.69a1.028 1.028 0 0 1 2.053 0v14.754a3.337 3.337 0 0 1-3.332 3.333 3.337 3.337 0 0 1-3.333-3.333V20.12Zm-4.227 4.556c.002-.004.004-.005.005-.004.117-.08.679-.663 1.663-.803.042 11.495-.093 11.191.123 12.237l-2.55-8.405a3.03 3.03 0 0 1 .76-3.025Z'
      />
      <path
        fill='#040325'
        d='M70.297 10.643a5.582 5.582 0 0 0-6.983-3.731l-2.647.801a1.28 1.28 0 0 0 .742 2.45c2.509-.726 2.784-.935 3.532-.935a3.06 3.06 0 0 1 2.908 2.16l3.676 12.119 1.132 3.731 12.856 42.386c.503 1.643-.47 3.322-2.026 3.793C32.248 88.96 35.322 88.098 34.636 88.098a3.06 3.06 0 0 1-2.908-2.159l-6.947-22.9a1.28 1.28 0 1 0-2.447.744l6.945 22.9a5.566 5.566 0 0 0 5.357 3.974c.549 0 1.096-.082 1.627-.242L70.099 80.15v3.005a1.279 1.279 0 1 0 2.558 0v-3.782l11.572-3.51a5.568 5.568 0 0 0 3.976-5.355c0-1.557.63 1.54-17.908-59.866Z'
      />
      <path
        fill='#040325'
        d='M50.115 37.777c-1.082-3.565-3.942-3.735-5.65-3.837-1.543-.113-2.616-.042-2.965-1.194-.335-1.105.444-1.677 1.551-2.013 1.62-.491 2.184.15 2.963-.087.916-.278 1.14-1.365.943-2.01-.416-1.374-2.51-1.232-3.633-1.05l-.155-.513a1.605 1.605 0 0 0-2-1.07 1.605 1.605 0 0 0-1.07 2l.215.707c-1.786 1.174-2.418 2.958-1.779 5.064.92 3.034 3.339 3.191 5.282 3.318 1.938.126 2.889.251 3.333 1.713.38 1.257-.096 2.041-1.5 2.467-2.262.686-2.601-.983-3.835-.609-.812.247-1.185 1.286-.963 2.016.363 1.198 2.214 1.956 4.241 1.787l.155.51a1.605 1.605 0 0 0 2 1.07 1.605 1.605 0 0 0 1.069-2.001l-.165-.544c1.995-1.197 2.71-3.26 1.963-5.724Z'
      />
      <path
        fill='#6C6D84'
        d='M69.769 44.49a1.28 1.28 0 0 0-1.596-.853L31.71 54.697a1.28 1.28 0 0 0 .743 2.449l36.464-11.06a1.28 1.28 0 0 0 .853-1.596ZM71.899 51.508a1.282 1.282 0 0 0-1.597-.853l-.203.062-36.26 10.999a1.281 1.281 0 0 0-.854 1.596 1.282 1.282 0 0 0 1.597.853l36.464-11.06a1.282 1.282 0 0 0 .853-1.597ZM74.027 58.526a1.28 1.28 0 0 0-1.595-.853l-2.333.708-34.133 10.354c-1.401.425-1.091 2.504.372 2.504.123 0 .248-.02.372-.056l36.464-11.06a1.28 1.28 0 0 0 .853-1.597ZM76.156 65.546a1.28 1.28 0 0 0-1.595-.853l-1.903.578L38.1 75.752a.07.07 0 0 1-.003.001c-1.404.425-1.09 2.504.37 2.504.288 0-1.855.626 36.836-11.117.677-.204 1.06-.92.853-1.594Z'
      />
      <path
        fill='#040325'
        d='M38.1 79.217h.315c1.89-2.47 3.152-3.466 3.152-3.466-.473.493-1.892 1.985-3.467 3.466Z'
      />
    </svg>
  );
};
