import React from 'react';

export const FacebookSvg: React.FC = () => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={8}
      height={12}
      fill='none'
    >
      <path
        fill='#040325'
        d='M7.25.002 5.693 0C3.945 0 2.815 1.16 2.815 2.953v1.362H1.251a.245.245 0 0 0-.245.245v1.973c0 .135.11.244.245.244h1.564v4.978c0 .135.11.245.245.245h2.041c.136 0 .245-.11.245-.245V6.777h1.83c.135 0 .244-.11.244-.244l.001-1.973a.245.245 0 0 0-.245-.245h-1.83V3.16c0-.555.132-.837.855-.837H7.25c.135 0 .245-.11.245-.245V.247A.245.245 0 0 0 7.25.002Z'
      />
    </svg>
  );
};
