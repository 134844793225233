import React from 'react';
import { theme } from '../../constants';

export const DepositPercentSvg: React.FC = () => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={52}
      height={52}
      fill='none'
    >
      <path
        fill={theme.colors.whiteText}
        stroke={theme.colors.main2Dark}
        strokeWidth={4}
        d='M10 26c0-8.837 7.163-16 16-16s16 7.163 16 16-7.163 16-16 16-16-7.163-16-16ZM26 2C12.745 2 2 12.745 2 26s10.745 24 24 24 24-10.745 24-24S39.255 2 26 2Z'
      />
      <path
        stroke={theme.colors.red}
        strokeLinecap='round'
        strokeWidth={4}
        d='M26 46c11.046 0 20-8.954 20-20S37.046 6 26 6'
      />
      <path
        fill={theme.colors.red}
        d='M19.598 31.168c-.579 0-1.097-.103-1.554-.308a2.711 2.711 0 0 1-1.078-.868 2.128 2.128 0 0 1-.392-1.274c0-.55.163-1.013.49-1.386.327-.373.7-.672 1.12-.896v-.056a3.712 3.712 0 0 1-.882-.868c-.233-.345-.35-.751-.35-1.218 0-.485.117-.9.35-1.246.233-.355.55-.63.952-.826.41-.196.873-.294 1.386-.294.803 0 1.437.224 1.904.672.476.439.714 1.017.714 1.736 0 .439-.126.835-.378 1.19s-.523.64-.812.854v.056c.41.233.77.527 1.078.882.308.355.462.83.462 1.428 0 .457-.126.868-.378 1.232a2.62 2.62 0 0 1-1.05.868c-.448.215-.975.322-1.582.322Zm.588-5.194a2.75 2.75 0 0 0 .546-.742 1.71 1.71 0 0 0 .196-.798c0-.392-.117-.714-.35-.966-.233-.261-.555-.392-.966-.392-.345 0-.635.107-.868.322-.233.215-.35.513-.35.896 0 .299.08.55.238.756.159.196.373.369.644.518.28.14.583.275.91.406Zm-.56 4.046c.43 0 .78-.117 1.05-.35.28-.243.42-.57.42-.98 0-.327-.098-.593-.294-.798a2.337 2.337 0 0 0-.77-.532 13.616 13.616 0 0 0-1.078-.476c-.28.205-.513.448-.7.728-.177.28-.266.593-.266.938 0 .439.159.793.476 1.064.317.27.705.406 1.162.406Zm6.156-2.548c-.644 0-1.172-.252-1.582-.756-.402-.513-.602-1.232-.602-2.156 0-.915.2-1.624.602-2.128.41-.504.938-.756 1.582-.756a1.91 1.91 0 0 1 1.568.756c.41.504.616 1.213.616 2.128 0 .924-.206 1.643-.616 2.156a1.91 1.91 0 0 1-1.568.756Zm0-.938c.298 0 .541-.159.728-.476.196-.317.294-.817.294-1.498 0-.69-.098-1.185-.294-1.484-.187-.308-.43-.462-.728-.462-.29 0-.532.154-.728.462-.196.299-.294.793-.294 1.484 0 .681.098 1.18.294 1.498.196.317.438.476.728.476Zm.294 4.634 4.998-9.492h.98l-4.984 9.492h-.994Zm6.272 0c-.644 0-1.172-.252-1.582-.756-.402-.513-.602-1.232-.602-2.156 0-.915.2-1.624.602-2.128.41-.504.938-.756 1.582-.756a1.91 1.91 0 0 1 1.568.756c.41.504.616 1.213.616 2.128 0 .924-.206 1.643-.616 2.156a1.91 1.91 0 0 1-1.568.756Zm0-.938c.298 0 .541-.159.728-.476.196-.317.294-.817.294-1.498 0-.69-.098-1.185-.294-1.484-.187-.308-.43-.462-.728-.462-.29 0-.532.154-.728.462-.196.299-.294.793-.294 1.484 0 .681.098 1.18.294 1.498.196.317.438.476.728.476Z'
      />
    </svg>
  );
};
