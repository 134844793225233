import React from 'react';

export const MobilePaymentSvg: React.FC = () => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={40}
      height={40}
      fill='none'
    >
      <rect
        width={40}
        height={40}
        fill='#FFF7F2'
        rx={20}
      />
      <path
        fill='#040325'
        stroke='#040325'
        strokeWidth={0.2}
        d='M21.247 18.378a.375.375 0 1 0 .75 0 1.999 1.999 0 0 0-1.622-1.959v-.92a.375.375 0 0 0-.75 0v.92a1.995 1.995 0 0 0 0 3.918v2.468a1.244 1.244 0 0 1-.872-1.183.375.375 0 1 0-.75 0 1.998 1.998 0 0 0 1.622 1.959v.919a.375.375 0 0 0 .75 0v-.92a1.998 1.998 0 0 0 0-3.917v-2.468a1.244 1.244 0 0 1 .872 1.183Zm-1.622 1.183a1.238 1.238 0 0 1 0-2.366v2.366Zm.75.878a1.239 1.239 0 0 1 0 2.366v-2.366Z'
      />
      <path
        fill='#040325'
        stroke='#040325'
        strokeWidth={0.2}
        d='M24.857 9.5h-9.714a1.502 1.502 0 0 0-1.5 1.5v18a1.502 1.502 0 0 0 1.5 1.5h9.714a1.502 1.502 0 0 0 1.5-1.5V11a1.501 1.501 0 0 0-1.5-1.5Zm-2.839.75-.375.75h-3.286l-.375-.75h4.036ZM25.608 29a.75.75 0 0 1-.75.75h-9.716a.75.75 0 0 1-.75-.75V11a.75.75 0 0 1 .75-.75h2.001l.646 1.293a.374.374 0 0 0 .336.207h3.75a.375.375 0 0 0 .335-.207l.647-1.293h2a.75.75 0 0 1 .75.75v18Z'
      />
    </svg>
  );
};
