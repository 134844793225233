import React from 'react';

export const PayCheckSvg: React.FC = () => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={40}
      height={40}
      fill='none'
    >
      <rect
        width={40}
        height={40}
        fill='#FFF7F2'
        rx={20}
      />
      <path
        fill='#040325'
        d='M28.64 11.84H11.36a1.44 1.44 0 0 0-1.44 1.44v13.44a1.44 1.44 0 0 0 1.44 1.44h17.28a1.44 1.44 0 0 0 1.44-1.44V13.28a1.44 1.44 0 0 0-1.44-1.44Zm.48 14.88a.48.48 0 0 1-.48.48H11.36a.48.48 0 0 1-.48-.48V13.28a.48.48 0 0 1 .48-.48h17.28a.48.48 0 0 1 .48.48v13.44Zm-13.44-7.2V17.6a1.018 1.018 0 0 1 .96.96.48.48 0 0 0 .96 0 1.978 1.978 0 0 0-1.92-1.92v-.48a.48.48 0 0 0-.96 0v.48a1.987 1.987 0 0 0-1.92 1.92 1.92 1.92 0 0 0 1.92 1.92v1.92a.96.96 0 0 1-.96-.96.48.48 0 0 0-.96 0 1.968 1.968 0 0 0 1.92 1.92v.49a.48.48 0 0 0 .96 0v-.49a1.92 1.92 0 1 0 0-3.84Zm-1.92-.96a.96.96 0 0 1 .96-.96v1.92a.96.96 0 0 1-.96-.96Zm1.92 3.84v-1.92a.96.96 0 1 1 0 1.92ZM27.2 20a.48.48 0 0 1-.48.48H20a.48.48 0 0 1 0-.96h6.72a.48.48 0 0 1 .48.48Zm0-2.88a.48.48 0 0 1-.48.48H20a.48.48 0 0 1 0-.96h6.72a.48.48 0 0 1 .48.48Zm0 5.76a.48.48 0 0 1-.48.48H20a.48.48 0 0 1 0-.96h6.72a.48.48 0 0 1 .48.48Z'
      />
    </svg>
  );
};
