import React from 'react';

export const PayPalSvg: React.FC = () => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={40}
      height={40}
      fill='none'
    >
      <rect
        width={40}
        height={40}
        fill='#FFF7F2'
        rx={20}
      />
      <path
        fill='#040325'
        d='M17.93 20.99c.1 0 2.42.1 3.8-.24h.01c1.59-.39 3.8-1.51 4.37-5.17 0 0 1.27-4.58-5.03-4.58h-5.41c-.49 0-.91.36-.99.84l-2.3 14.56c-.05.3.19.58.49.58h3.43l.84-5.32c.06-.38.39-.67.79-.67Z'
      />
      <path
        fill='#040325'
        d='M26.99 16.29c-.81 3.73-3.36 5.7-7.42 5.7H18.1l-1.03 6.52c-.04.26.16.49.42.49h1.9c.34 0 .64-.25.69-.59.08-.4.52-3.32.61-3.82.05-.34.35-.59.69-.59h.44c2.82 0 5.03-1.15 5.68-4.46.26-1.34.12-2.44-.51-3.25Z'
      />
    </svg>
  );
};
