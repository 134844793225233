import React from 'react';

type Props = {
  color?: string;
};

export const GamesSvg: React.FC<Props> = ({color = '#FF8A71'}) => {
  return (
    <svg
        width={50}
        height={24}
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        viewBox="0 0 512 512"
        xmlSpace="preserve"
        >
        <g>
            <path
            fill={color}
            d="M511.275,335.864c-13.696-182.839-84.68-268.054-158.186-268.054c-55.32,0-73.124,39.474-97.088,39.474
                c-23.964,0-41.824-39.474-97.089-39.474c-73.505,0-144.49,85.216-158.186,268.054c-5.225,57.878,18.231,94.731,56.036,105.86
                c38.222,11.256,84.926-16.545,123.429-72.472c17.151-24.925,46.267-58.459,75.81-58.459c29.542,0,58.658,33.534,75.81,58.459
                c38.504,55.927,85.206,83.728,123.428,72.472C493.053,430.595,516.5,393.742,511.275,335.864z M198.694,252.418h-37.116v37.116
                H120.87v-37.116H83.755v-40.708h37.115v-37.115h40.708v37.115h37.116V252.418z M321.914,257.768c-11.864,0-21.47-9.596-21.47-21.46
                c0-11.855,9.606-21.461,21.47-21.461c11.854,0,21.47,9.606,21.47,21.461C343.384,248.172,333.769,257.768,321.914,257.768z
                M373.77,309.642c-11.846,0-21.452-9.606-21.452-21.469c0-11.855,9.606-21.461,21.452-21.461c11.864,0,21.469,9.606,21.469,21.461
                C395.239,300.036,385.633,309.642,373.77,309.642z M373.77,205.904c-11.846,0-21.452-9.614-21.452-21.469
                c0-11.864,9.606-21.469,21.452-21.469c11.864,0,21.469,9.606,21.469,21.469C395.239,196.29,385.633,205.904,373.77,205.904z
                M425.642,257.768c-11.854,0-21.469-9.596-21.469-21.46c0-11.855,9.615-21.461,21.469-21.461c11.865,0,21.469,9.606,21.469,21.461
                C447.111,248.172,437.507,257.768,425.642,257.768z"
            />
        </g>
    </svg>

  );
};
